import React, {useEffect, useMemo, useRef, useState} from 'react';
import 'datatables.net';
import $ from "jquery";
import cogoToast from "cogo-toast";
import {simulator, getSimulator, grade, addNewCategory, getCategory} from "../../request";
import generateId from "../../utils/generateChar";
import AWS from "aws-sdk";
import {Link} from "react-router-dom";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const bucket = new AWS.S3({
    accessKeyId: process.env.REACT_APP_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
    signatureVersion: 'v4'
});

const VirtualLab = () => {

    document.title = "Virtual Lab";

    const quillRef = useRef();

    const [formLoading, setFormLoading] = useState(false);
    const [loading, setLoading] = useState(true);
    const [editForm, setEditForm] = useState(false);
    const [stateReady, setStateReady] = useState(false);
    const [cover_image, set_cover_image] = useState(null);
    let [virtualList, setVirtualList] = useState([]);
    const [value, setValue] = useState(null);

    useEffect(() => {
        getSimulator().then((data) => {
            if(!data.error) {
                setVirtualList(data.payload);
                setLoading(false);
            }
        });
    }, []);

    const handleFileUpload = (event) => {
        if(event.target.files[0] !== undefined && $('#cover_image').parsley().isValid() !== false) {
            let file = event.target.files[0];
            let image = new Image();
            image.src = window.URL.createObjectURL(file);
            image.onload = function() {
                set_cover_image(file);
            };
        }
    };

    const S3upload = (file) => {
        const params = {
            Bucket: "s3.zstudy",
            Key: `uploads/${generateId(50)}`,
            Body: file,
            ContentType: file.type
        };

        return bucket.upload(params, function (err, data) {
            if (err) return console.log({ err });
            return data;
        }).promise();
    }

    const resetForm = (id, form) => {
        const parsley = $(`#${form}`).parsley();
        $(':input',`#${form}`)
            .not(':button, :submit, :reset, :hidden')
            .val('')
            .prop('checked', false)
            .prop('selected', false);
        $(`#${id}`).modal("hide");
        parsley.reset();
        parsley.destroy();
        $(".modal-backdrop").remove();
    };

    const onCloseModal = (id, form) => {
        set_cover_image(null);
        $(':input',`#${form}`)
            .not(':button, :submit, :reset, :hidden')
            .val('')
            .prop('checked', false)
            .prop('selected', false);
        $(`#${id}`).modal("hide");
        $(`#${form}`).parsley().reset();
        $(".modal-backdrop").remove();
    };

    const handleShowModal = () => {
        setEditForm(false);
        $("#html_file").prop("required", true);
        $("#cover_image").prop("required", true);
        $('#simulator_form').parsley();
        $("#modalSimulator").modal({backdrop: 'static', keyboard: false}, "show");
    }

    const handleDeleteSimulator = (id) => {
        let data = {
            id
        }

        let {hide} = cogoToast.loading('Please wait... contacting to server.', {
            position: "top-right",
            hideAfter: 5
        });

        simulator("delete", data).then((result) => {
            hide();
            if(!result.error) {
                cogoToast.success("Simulator successfully created.", {position: "top-right", hideAfter: 5});
                getSimulator().then((data) => {
                    if(!data.error) {
                        setVirtualList(data.payload);
                    }
                });
            }
        });
    }

    const handleSimulatorSubmit = async(e) => {
        e.preventDefault();
        setFormLoading(true);

        let file = $('#html_file').prop('files')[0];

        let upload = await S3upload(file);

        let cover_file = $('#cover_image').prop('files')[0];

        let cover_upload = await S3upload(cover_image);

        let data = {
            name: $("#name").val(),
            instruction: btoa(value),
            description: $("#description").val(),
            cover: {
                name: cover_file.name,
                type: cover_file.type,
                size: cover_file.size.toString(),
                location: cover_upload.Location
            },
            file: {
                name: file.name,
                type: file.type,
                size: file.size.toString(),
                location: upload.Location
            }
        }

        if(editForm) {
            simulator("put", data).then((result) => {
                setFormLoading(false);

                if(!result.error) {
                    setValue(null);
                    cogoToast.success("Simulator successfully updated.", {position: "top-right", hideAfter: 5});
                    resetForm("modalSimulator", "simulator_form");
                    getSimulator().then((data) => {
                        if(!data.error) {
                            setVirtualList(data.payload);
                        }
                    });
                }
            });

            return false;
        }

        simulator("post", data).then((result) => {
            setFormLoading(false);

            if(!result.error) {
                setValue(null);
                cogoToast.success("Simulator successfully created.", {position: "top-right", hideAfter: 5});
                resetForm("modalSimulator", "simulator_form");
                getSimulator().then((data) => {
                    if(!data.error) {
                        setVirtualList(data.payload);
                    }
                });
            }
        });
    }

    const editShowSimulator = (data) => {
        setEditForm(true);

        $("#html_file").prop("required", false);
        $("#cover_image").prop("required", false);
        $("#name").val(data.name);
        $("#description").val(data.description);

        setValue(atob(data.instruction));

        $('#simulator_form').parsley();
        $("#modalSimulator").modal({backdrop: 'static', keyboard: false}, "show");

    }

    const imageHandler = () => {
        const editor = quillRef.current.getEditor();
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();

        input.onchange = async () => {
            const file = input.files[0];
            if (/^image\//.test(file.type)) {
                const res = await S3upload(file); // upload data into server or aws or cloudinary
                const url = res?.Location;
                editor.insertEmbed(editor.getSelection(), "image", url);
            } else {
                cogoToast.error('You could only upload images.', {hideAfter: 3});
            }
        }
    }

    const modules = useMemo(
        () => ({
            toolbar: {
                container: [
                    [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
                    [{size: []}],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [{'list': 'ordered'}, {'list': 'bullet'},
                        {'indent': '-1'}, {'indent': '+1'}],
                    ['image'],
                    ['clean']
                ],

                handlers: {
                    image: imageHandler.bind(),
                },
                history: {
                    delay: 500,
                    maxStack: 100,
                    userOnly: true,
                },
            },
        }),
        []
    );

    const virtualListContent = () => {
        if(virtualList === null || loading) {
            return(
                <div className="content content-fixed content-auth-alt">
                    <div className="container d-flex justify-content-center ht-100p">
                        <div className="mx-wd-300 wd-sm-450 ht-100p d-flex flex-column align-items-center justify-content-center">
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                            <p className="mg-t-10">Loading Virtual Lab</p>
                        </div>
                    </div>
                </div>
            )
        } else {
            let renderAd = () => {
                if(virtualList.length < 1) {
                    return(
                        <div className="container d-flex justify-content-center ht-100p mg-t-50">
                            <div className="ht-100p d-flex flex-column align-items-center justify-content-center">
                                <div className="container ht-100p">
                                    <div className="ht-100p d-flex flex-column align-items-center justify-content-center">
                                        <div className="wd-80p wd-sm-300 mg-b-15 tx-center"><i className="fa-5x fad fa-folder-times"/></div>
                                        <h4 className="tx-20 tx-sm-24 tx-center">Empty</h4>
                                        <p className="tx-color-03 mg-b-40">No Virtual Lab is available or has been removed.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }

                return virtualList.map((result) => {
                    return (
                        <a href={result.file.uri} target="_blank" className="nav-link outline-none tx-14 col-md-4" key={result._id}>
                            <div className="card card-body pd-12 mg-b-8">
                                <img src={result.cover.uri} className="ht-250 wd-100p rounded mg-r-10 mg-b-0 img-fluid" alt=""/>
                                <a className="nav-link ml-0 outline-none tx-14 text-truncate">{result.name}</a>
                                <nav className="nav nav-icon-only">
                                    <a href="javascript:void(0)" className="nav-link" onClick={editShowSimulator.bind(null, result)}><i className="fa fa-pencil"></i> Edit</a><a href="javascript:void(0)" onClick={handleDeleteSimulator.bind(null, result._id)} className="nav-link"><i className="fa fa-trash-alt"></i> Delete</a>
                                </nav>
                            </div>
                        </a>
                    )
                });
            };

            let renderProceedButton = () => {
                if(stateReady === true) {
                    return(
                        <button className="btn btn-brand-02 btn-block" disabled><span className="spinner-border spinner-border-sm mg-r-10" aria-hidden="true"/><span id="status">Please Wait</span></button>
                    )
                } else {
                    return(
                        <button className="btn btn-success btn-block"><i className="fa fa-check-circle"/> Add</button>
                    );
                }
            };

            return(
                <div className="content-body">
                    <div className="container-fluid pd-x-0">
                        <div
                            className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                            <div>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                        <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Virtual Lab</li>
                                    </ol>
                                </nav>
                                <h4 className="mg-b-0 tx-spacing--1">Virtual Lab</h4>
                            </div>
                            <div className="d-none d-md-block">
                                <button className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5 outline-none"
                                        onClick={handleShowModal.bind()}><i className="wd-10 mg-r-5 fa fa-plus"></i> Add
                                    New
                                </button>
                            </div>
                        </div>

                        <div className="row row-xs">
                            {renderAd()}
                        </div>
                    </div>

                    <div className="modal fade" id="modalSimulator" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                                    <a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </a>
                                    <div className="media-body">
                                        <a href="" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" onClick={() => {$("#file").prop('required', true); onCloseModal("modalSimulator", "simulator_form")
                                        }} aria-label="Close">
                                            <span aria-hidden="true">×</span>
                                        </a>
                                        <h4 className="tx-18 tx-sm-20 mg-b-2" id="edit_title">Interactive Learning</h4>
                                        <p className="tx-13 tx-color-02 mg-b-0" id="edit_subtitle">Fill all the required field</p>
                                    </div>
                                </div>
                                <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                                    <form id="simulator_form" className="parsley-style-1" data-parsley-validate="true" noValidate onSubmit={handleSimulatorSubmit.bind()}>
                                        <div id="nameWrapper" className="form-group parsley-input">
                                            <label>Name<span className="tx-danger">*</span></label>
                                            <input id="name" type="text" className="form-control"
                                                   placeholder="Enter name"
                                                   autoComplete="off"
                                                   data-parsley-class-handler="#nameWrapper" required/>
                                        </div>

                                        <div className="form-group">
                                            <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Cover
                                                Image</label>
                                            <input id="cover_image" type="file" className="form-control"
                                                   onChange={handleFileUpload.bind()}
                                                   data-parsley-filemaxmegabytes="1"
                                                   data-parsley-trigger="change"
                                                   data-parsley-filemimetypes="image/jpeg, image/jpg"
                                                   data-parsley-max-file-size="2048576"
                                                   accept="image/jpeg, image/jpg"
                                                   required
                                            />
                                        </div>

                                        <div id="DescriptionWrapper" className="form-group parsley-input">
                                            <label>Description<span className="tx-danger">*</span></label>
                                            <textarea id="description" rows="5" className="form-control"
                                                      placeholder="Provide a description"
                                                      data-parsley-class-handler="#DescriptionWrapper"
                                                      required></textarea>
                                        </div>

                                        <div className="mb-3">
                                            <label>Instruction<span className="tx-danger">*</span></label>
                                            <ReactQuill
                                                id={"quill-id"}
                                                theme="snow"
                                                value={value}
                                                onChange={setValue}
                                                modules={modules}
                                                ref={quillRef}
                                                placeholder={'Write something...'}
                                            />
                                        </div>

                                        <div className="form-group">
                                            <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Upload
                                                File</label>
                                            <input id="html_file" type="file" className="form-control"
                                                   data-parsley-filemaxmegabytes="1"
                                                   data-parsley-trigger="change"
                                                   data-parsley-filemimetypes="text/html"
                                                   accept="text/html"
                                                   multiple
                                                   required
                                            />
                                        </div>
                                        {formLoading ? <button disabled className="btn btn-brand-02 btn-block"><span
                                                className="spinner-border spinner-border-sm mg-r-10" role="status"
                                                aria-hidden="true"/>Please Wait</button> :
                                            editForm ? <button className="btn btn-brand-02 btn-block mt-2"><i
                                                    className="ion-md-add"/> Update Simulator</button> :
                                                <button className="btn btn-brand-02 btn-block mt-2"><i
                                                    className="ion-md-add"/> Add Simulator</button>}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    };

    return (
        virtualListContent()
    )
};

export default VirtualLab;
